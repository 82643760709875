.p-ending {
  h1 {
    text-align: center;
    text-transform: uppercase;
    @include font-base(28, 37);

    @include mobile-down {
      @include font-base(26, 37);
    }
  }
}
