.t-customerInfo {
  max-width: 775px;
  margin: auto;

  &_form {
    margin-top: 24px;

    &_field {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &:not(:first-child) {
        margin-top: 24px;
      }
    }

    &_label {
      width: 100%;
      color: $arsenic;

      @include mobile-up {
        margin-right: 12px;
        flex: 0 0 25%;
        max-width: 25%;
        font-size: rem(20);
      }
    }

    &_submit {
      flex: 1 0 0;
      max-width: 210px;

      @include mobile-down {
        margin: auto;
      }
    }

    &_input {
      flex: 1 0 0;

      @include mobile-down {
        margin-top: rem(8);
      }

      &.flex {
        display: flex;
        align-items: center;

        .a-input {
          flex: 1 0 0;
        }
      }
    }

    &_btnOtp {
      width: 115px;
      margin-left: 9px;
    }

    &_check {
      width: rem(35);
      height: rem(35);
      border-radius: 50%;
      margin-left: rem(10);
      @include adjust-flex(center, center);

      img {
        width: 100%;
      }
    }
  }

  &_title {
    text-align: center;
  }
}
