.m-pulldown {
  $root: &;
  position: relative;


  &_icon-indicator {
    background: url('~assets/icons/ic_chevron-down.svg') no-repeat -5px center/100%;
    background-size: contain;
    height: rem(30);
    position: relative;
    transform: rotate(0);
    transition: all $transition-long ease;
    width: rem(30);
  }

  // &__single-value {
  //   @include tablet-down {
  //     @include font-base(10, 18);
  //   }
  // }

  &__option {
    margin-top: rem(12) !important;
    // text-transform: uppercase;
    // @include tablet-down {
    //   font-size: rem(10) !important;
    //   line-height: rem(18) !important;
    //   margin-top: rem(7) !important;
    // }

    &:first-child {
      margin-top: 0;
    }
  }

  &__control {
    @include tablet-down {
      padding-left: rem(9) !important;
    }

    &--is-focused {
      box-shadow: none !important;
    }
  }

  &__value {
    &-container {
      display: block;
      height: 100%;
      text-transform: uppercase;
    }
  }

  &__placeholder {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-transform: none;
    @include font-base(18, 28);
    // @include desktop-down {
    //   font-size: rem(12) !important;
    // }
    // @include tablet-down {
    //   font-size: rem(10) !important;
    // }
    // @include mobile-down {
    //   font-size: rem(8) !important;
    // }
  }

  &_label {
    margin-bottom: rem(8);
    text-transform: capitalize;
    @include font-base(16, 21);
  }

  &-errorMessage {
    bottom: rem(-20);
    color: red;
    left: 0;
    margin-top: rem(4);
    position: absolute;
    @include font-base(12, 18);
  }
}
