.a-input {
  $root: &;
  position: relative;
  width: 100%;

  &-flex {
    display: flex;
    align-items: center;

    #{$root}_label {
      margin-bottom: 0;
      flex: 0 0 30%;
      max-width: 30%;
      margin-right: 12px;
    }

    #{$root}_container {
      flex: 1 0 0;
    }
  }

  &_errorMessage {
    bottom: rem(-20);
    color: red;
    left: 0;
    margin-top: rem(4);
    position: absolute;
    @include font-base(12, 18);
  }

  &_label {
    margin-bottom: rem(8);
    text-transform: capitalize;
  }

  &_container {
    position: relative;
  }

  &-h44 {
    #{$root}_inputele {
      height: rem(44);
      @include font-base(16x24);
    }
  }

  &-otp {
    height: rem(48);
    width: rem(48);
    @include mobile-down {
      height: rem(40);
      width: rem(40);
    }
    #{$root}_inputele {
      // border: 1px solid $gainsboro;
      border-radius: 5px;
      // color: $oxford-blue;
      height: 100%;
      padding: 0;
      text-align: center;
      width: 100%;
    }
    #{$root}_container {
      height: 100%;
      width: 100%;
    }
  }

  &_inputele {
    appearance: none;
    border: 0;
    border-radius: 5px;
    border: 1px solid #8c8c8c;
    border-radius: 5px;
    margin: 0;
    outline: none;
    padding: rem(10) rem(20);
    transition: all $transition linear;
    width: 100%;
    @include font-base(18, 28);

    &:not(#{$root}-otp) & {
      border: 0;
      border-radius: rem(8);
      padding: rem(14) rem(20);
    }

    @include tablet-down {
      padding: rem(8) rem(16);
    }

    &-error {
      border-color: red;
    }

    &-noCursor {
      pointer-events: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }

    &::placeholder {
      // color: rgba($jet, 0.6);
    }
  }
}
