.a-button {
  $root: &;
  align-items: center;
  background: #dd2327;
  border: 0;
  border-radius: rem(8);
  color: #fff;
  cursor: pointer;
  display: flex;
  font: inherit;
  font-weight: 700;
  height: rem(55);
  justify-content: center;
  margin: 0;
  outline: none;
  overflow: hidden;
  padding: rem(4);
  position: relative;
  text-transform: uppercase;
  transition: $transition-long ease;
  width: 100%;
  @include font-base(16, 24);

  @include tablet-down {
    @include font-base(16, 20);
    height: rem(49);
  }

  &-otp {
    @include desktop-up {
      @include font-base(16, 22);
    }
  }

  &-secondary {
    background: linear-gradient(to top, #2891cf 0%, #183f6d 100%);

    &#{$root}-loading {
      background: linear-gradient(to top, #2891cf 0%, #183f6d 100%);
      opacity: 0.8;
    }
  }

  &-loading {
    pointer-events: none;
    background: rgba(#dd2327, 0.8);

    &::before {
      background: gray;
    }
  }

  &-disabled {
    color: rgba(#fff, 0.7);
    filter: grayscale(1);
    pointer-events: none;
  }

  &_loadingIcon {
    position: relative;
    width: rem(30);
    z-index: $z-2;
  }

  &_wrapper {
    // background: $linear-seal-brown4;
    border-radius: rem(66);
    height: 100%;
    width: 100%;
    @include adjust-flex(center, center);
  }

  span {
    position: relative;
    z-index: $z-2;
  }
}
