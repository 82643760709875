.t-congratulation {
  @include max-screen-mobile;


  &_notice {
    text-align: center;
    text-transform: capitalize;
    font-size: rem(24);
    font-weight: 600;
    color: $arsenic;

    @include mobile-down {
      font-size: rem(18);
    }

    span {
      display: block;
    }
  }

  &_desc {
    text-align: center;
    font-size: rem(18);
    color: $arsenic;
    font-style: italic;

    @include mobile-down {
      font-size: rem(15);
    }
  }

  &_gift {
    &_image {
      width: rem(200);
      margin: rem(20) auto;

      img {
        width: 100%;
      }
    }
  }

  &_qr {
    text-align: center;

    .desc {
      font-size: rem(24);
      color: $arsenic;
      text-align: center;

      @include mobile-down {
        font-size: rem(18);
      }

      span {
        display: block;
      }
    }

    &_code {
      margin-top: rem(15);
      margin-bottom: rem(15);
    }
  }


  &_button {
    max-width: rem(240);
    margin: rem(40) auto 0;

    @include mobile-down {
      margin-top: rem(24);
    }
  }
}
